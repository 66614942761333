import React from 'react';
import styles from './guide.module.scss';
import { LogoChave, LogoGame } from './icons';

export default function Guide() {
	return (
		<div className={styles.investigadores}>
			<div className={styles.header}>
				{/* <div style={{ backgroundImage: "url('/investigadores/bg.png')" }}> */}
				<div className={styles.LogoChave}>
					<LogoChave />
				<div className={styles.LogoGame}>
				<LogoGame />
				</div>
			</div>
			<div className={styles.title}>
				<h4>Manual de regras</h4>
			</div>
			<div className={styles.text}>
				<img src='/investigadores/text.png' alt='Manual Investigadores'/>
			</div>
			
		</div>
		</div>
	);
}
