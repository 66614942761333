import { useState } from 'react';
import styles from './index.module.scss';
import { clueList } from '../../components/PocketPuzzle/ClueList/clueList.js';
import ModalClue from '../../components/generics/ModalClue/modalClue.js';
import showToast from '../../lib/toast.js';
import ModalGuide from '../../components/PocketPuzzle/ModalGuide/modalGuide.js';
import AudioPlayerComponent from '../../components/PocketPuzzle/AudioPlayerComponent/AudioPlayerComponent.js';
import ModalErratas from '../../components/PocketPuzzle/ModalErratas/modalErratas.js';

export default function Index({ hasErrataBtn }) {
	const [openModalGuide, setOpenModalGuide] = useState(false);
	const [openModalErratas, setOpenModalErratas] = useState(false);

	function handleOpenModalGuide() {
		setOpenModalGuide(true);
	}
	function handleOpenModalErratas() {
		setOpenModalErratas(true);
	}
	return (
		<div
			className={styles.pocketPuzzle}
			style={{ backgroundImage: "url('/PocketPuzzle/bg.png')" }}
		>
			<header className={styles.header}>
				<img
					src="/PocketPuzzle/headerImg.png"
					alt="Imagem do Logo Barba Negra"
					className={styles.headerImage}
				/>
			</header>
			{hasErrataBtn && (
				<button
					className={styles.errataButton}
					onClick={handleOpenModalErratas}
				>
					ATENÇÃO: ERRATAS
				</button>
			)}

			<button
				className={styles.guideButton}
				onClick={handleOpenModalGuide}
			>
				SOBRE ESTE GUIA
			</button>
			<CluesComponent
				openModalGuide={openModalGuide}
				setOpenModalGuide={setOpenModalGuide}
				openModalErratas={openModalErratas}
				setOpenModalErratas={setOpenModalErratas}
			/>
			<AudioPlayerComponent />
		</div>
	);
}

function CluesComponent({
	openModalGuide,
	setOpenModalGuide,
	openModalErratas,
	setOpenModalErratas,
}) {
	const [openModal, setOpenModal] = useState(false);
	const [currentClue, setCurrentClue] = useState(null);
	const [openClue, setOpenClue] = useState([1]);

	function wrapperOpenModal(clue) {
		if (isOpenedClue(clue.id)) {
			showToast('As dicas devem ser abertas sequencialmente.', 'error');
			return;
		}
		handleOpenModal(clue);
	}

	function handleOpenModal(clue) {
		setCurrentClue(clue);
		setOpenModal(true);
	}

	function isOpenedClue(clueId) {
		return !openClue.includes(clueId);
	}

	function handleCloseModal() {
		setOpenModal(false);
		setCurrentClue(null);
	}

	function handleClue() {
		const nextClue = currentClue.id + 1;
		if (nextClue <= clueList.length && !openClue.includes(nextClue)) {
			setOpenClue((prevState) => [...prevState, nextClue]);
		}
	}

	function handleCloseModalErratas() {
		setOpenModalErratas(false);
	}
	function handleCloseModalGuide() {
		setOpenModalGuide(false);
	}
	return (
		<div className={styles.cluesComponent}>
			<div className={styles.headerClues}>
				<span>DICAS:</span>
			</div>
			<div className={styles.clueList}>
				{clueList.map((clue) => (
					<button
						className={`${styles.clueButton} ${
							isOpenedClue(clue.id) && styles.disabled
						}`}
						key={`clue_${clue.id}`}
						onClick={() => wrapperOpenModal(clue)}
					>
						{clue.title}
					</button>
				))}
				{openModal && (
					<ModalClue
						clue={currentClue}
						onClose={handleCloseModal}
						onConfirm={handleClue}
					/>
				)}
				{openModalErratas && (
					<ModalErratas onClose={handleCloseModalErratas} />
				)}

				{openModalGuide && (
					<ModalGuide onClose={handleCloseModalGuide} />
				)}
			</div>
		</div>
	);
}
