import styles from './modalErratas.module.scss';


export default function ModalErratas( {onClose} ) {

    return (
        <div className={styles.modalErratas}>
            <div className={styles.modalContent}>
            <h2>ATENÇÃO: ERRATAS</h2>
            <img src="/PocketPuzzle/errata1.svg" alt="Imagem Errata 1" className={styles.image1} />

            <p>1. O texto da carta de intinerário está incorreto, o correto é “os ventos do Oeste.”</p> <br/>
            <p>O texto da carta antigo: <br />
                Aproveitando <span className={styles.redText}>os ventos do Leste</span>, seguimos; <br />
                O texto da errata: <br />
                Aproveitando <span className={styles.greenText}>os ventos do Oeste</span>, seguimos; </p> <br/>
            <p>2. Os símbolos em uma das metades do disco móvel estão incorretos, abaixo ilustramos a correção:</p>
            <img src="/PocketPuzzle/errata2.1.svg" alt="Imagem Errata 2" className={styles.image1} />
            <img src="/PocketPuzzle/errata2.2.svg" alt="Imagem Errata 3" className={styles.image1} /> 

            <span>ENTRE EM CONTATO QUE ENVIAMOS SEM CUSTO UMA NOVA CARTA E DISCO PARA VOCÊ: <br/>
            CONTATO@CHAVEMESTRA.NET</span>
            </div>
            <button className={styles.closeButton} onClick={onClose}>
                    FECHAR
                </button>
        </div>
    )
}