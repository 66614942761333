import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes as ReactRoutes,
} from 'react-router-dom';
import Index from '../pages/PocketPuzzle/Index'
import Guide from '../pages/investigadores/guide/guide'
import Home from '../pages/Home/home';

export default function Routes() {
	return (
		<Router>
			<ReactRoutes>
				<Route path="/" element={<Home />} />
				<Route path="/pocket-puzzle" element={<Index hasErrataBtn={true}/>} />
				<Route path="/pocket-puzzlev2" element={<Index/>} />
				<Route path="/investigadores/guide" element={<Guide />} />
			</ReactRoutes>
		</Router>
	);
}
  